export const strict = false;
import marks from "~/apollo/queries/marks";
import settings from "~/apollo/queries/settings";
import banks from "~/apollo/queries/bank/banks";

export const state = () => ({
  site_id: null,
  domain: ""
});
export const getters = {
  site_id: state => {
    return state.site_id;
  },
  // isMoscow:(state) =>{
  //    return state.site_id === 21 || state.site_id === 28
  // },
  domain: state => {
    return state.domain;
  }
};
export const actions = {
  async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {
    // let site_id = 400;
    // console.log($config.site_id)
    commit("SET_SITE_ID", $config.site_id);
    // commit('SET_SITE_ID', 200);
    commit("SET_DOMAIN", $config.domain);

    let client = app.apolloProvider.defaultClient;
    // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
    commit("banks/SET_PERCENT", $config.default_percent);

    if (req.headers.host === "xn----7sbgnzet2ad.xn--p1ai") {
      commit("SET_SITE_ID", $config.site_id_mirror1);
      commit("SET_DOMAIN", $config.domain_mirror1);
    } // авто-лекс.рф

    if (req.headers.host === "leks-dealer.ru") {
      commit("SET_SITE_ID", $config.site_id_mirror2);
      commit("SET_DOMAIN", $config.domain_mirror2);
    }

    if (req.headers.host === "leks-probeg.ru") {
      commit("SET_SITE_ID", $config.site_id_mirror3);
      commit("SET_DOMAIN", $config.domain_mirror3);
    }

    if (req.headers.host === "leksauto-motors.ru") {
      commit("SET_SITE_ID", $config.site_id_mirror4);
      commit("SET_DOMAIN", $config.domain_mirror4);
    }

    if (req.headers.host === "xn----itbpcimaqefi.xn--p1ai") {
      commit("SET_SITE_ID", $config.site_id_mirror5);
      commit("SET_DOMAIN", $config.domain_mirror5);
    } // лекс-моторс.рф

    //TODO НАСТРОЙКИ САЙТА
    let currentSettings = {};
    let settingsResponse = await client.query({
      query: settings,
      variables: { site_id: state.site_id }
    });
    settingsResponse.data.settings.settings.map(value => {
      currentSettings[value.key] = value.value;
    });
    commit("settings/settings/SET_SETTINGS", currentSettings);

    //   commit('SET_DOMAIN', currentSettings.domain.slice(0, -1));

    // TODO ПОЛУЧАЮ СПИСОК МАРОК
    let response = await client.query({
      query: marks,
      variables: { site_id: state.site_id, category: "cars" }
    });
    commit("marks/marks/SET_ALL_MARKS", response.data.marks);

    // let responseNew = await client.query(
    //     {
    //         query: marks,
    //         variables: {site_id: state.site_id, category: 'new'}
    //     })
    // commit('marks/marks/SET_ALL_MARKS_NEW', responseNew.data.marks)
    let responseBanks = await client.query({
      query: banks,
      variables: { site_id: state.site_id },
      fetchPolicy: 'no-cache'
    });
    commit("banks/SET_BANKS", responseBanks.data.banks);
  },
  async request({ state }, { query, variables }) {
    let assignVariables = {
      site_id: state.site_id
    };
    let client = this.app.apolloProvider.defaultClient;
    let params = { ...assignVariables, ...variables };
    return await client.query({
      query: query,
      variables: this.$removeEmptyObjects(params),
      fetchPolicy: "no-cache"
    });
  }
};
export const mutations = {
  SET_SITE_ID(state, data) {
    state.site_id = data;
  },
  SET_DOMAIN(state, data) {
    state.domain = data;
  }
};
