export const state = () => ({
    stateModalFavorites: false
})
export const getters = {
    stateModalFavorites: (state) => {
        return state.stateModalFavorites
    },
}
export const mutations = {
    SET_STATE_MODAL_FAVORITES(state, param) {
        state.stateModalFavorites = param
    },
}
