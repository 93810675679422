export const state = () => ({
  settings: {},
  isNight: false,
});

export const getters = {
  settings: state => {
    return state.settings;
  },
  isNight: state => {
    return state.isNight;
  },
};
export const mutations = {
  SET_SETTINGS(state, data) {
    state.settings = data;
  },
  SET_NIGHT(state, data) {
    state.isNight = data;
  },
};
