export const state = () => ({
    reload: true
})
export const getters = {
    reload: (state) => {
        return state.reload
    },
}
export const mutations = {
    setReload(state, data) {
        state.reload = data
    },
}